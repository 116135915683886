import { Button, Col, Input, Row, Space, Table } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { useReactToPrint } from 'react-to-print';


const MultiSelectTable = (props) => {

  let { columns, loader, rowSelection, ...otherProps } = props;
  const componentRef = useRef();
  
  let data = []
  props.dataSource.forEach(element => {
    
    data.push({
      ContactLink: element.ContactLink,
      AvailableforPuchchase: element.AvailableforPuchchase,
      ShowTree: element.ShowTree,
      Headlinde: element.Headlinde,
      subline: element.subline,
      Description: element.Description,
      Latitude: element.Latitude,
      Longitude: element.Longitude,
      Colors: element.Colors,
      Model: element.Model,
      ButtonText: element.ButtonText
    })
  });




  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const tab = useMemo(() => <div>
    <Row>
      <Col className='csvPdfBtn' span={24} md={24}>
        {/* <Button className='pdfBtn' onClick={handlePrint} type="primary" danger> Export to PDF </Button> */}
        <CSVLink
          filename={"Expense_Table.csv"}
          data={data}
          className="csvBtn da-pb-6 "
        >
          Export to CSV
        </CSVLink>
      </Col>
    </Row>


    {props.dataSource &&
      <div ref={componentRef}>
        <Table
          defaultExpandAllRows={true}

          columns={columns}
          dataSource={props.dataSource}
          loading={loader}
          scroll={{ x: 240 }}
        />
      </div>
    }



  </div>, [otherProps.dataSource, loader])
  return tab;
};

export default MultiSelectTable;
