import { UploadOutlined } from "@ant-design/icons";
import { ButtonComponent } from "@comps/components";
import { makeRequest, notify } from "@utils/helpers";
import { Button, Col, Form, Input, message, Row, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { createUser, delFile } from "../../requests";

import { SketchPicker, BlockPicker } from "react-color";
import { Link, useHistory } from "react-router-dom";


const BasicInformation = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState("");
  const [errors, setErrors] = useState([]);
  const [select, setSelect] = useState("");
  const [color, setColor] = useState({ color: "red" });
  const [fileUrl, setFileUrl] = useState("");
  const [sketchPickerColor, setSketchPickerColor] = useState("");

  const prop = {
    name: "modal",
    action: `${process.env.REACT_APP_API_BASE_URL}/poi/upload-modal`,
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        setFileUrl(info.file?.response?.response?.url)
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(info) {
      let payload = {
        path: info.response.response.url
      }
      makeRequest(setLoader, delFile, payload, null, null);
    },
  };
  const onFinish = (payload) => {

    let load = {
      ModalLink: fileUrl != "" && select == "Package C" ? `https://app.badkissingenentdecken.de/${fileUrl}` : "",
      Colors: sketchPickerColor,
      ...payload,
    }

    makeRequest(setLoader, createUser, load, onSuccess, onError);
  };
  const handleChange = (value) => {
    setSelect(value);
  };
  const onSuccess = (data, res) => {

    notify("Point Created Successfully", res.msg);
    history.push(`/poi/view-poi`);
  };

  const onError = (err, res) => {

    notify("Error! Something went wrong ");


  };

  //  const onDrag = (color, c) => {
  //   debugger
  //     this.setState({
  //       color
  //     })
  //   }
  const fileList = [

  ];

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Row align="space-between" >
            <Col span={12} >

              <h2 className="posHeadres">Add POINT</h2>
            </Col>
            <Link to="/" >
              <Button style={{ height: "50px", marginTop: "35px", marginRight: "35px", backgroundColor: "#0093CD", fontWeight: "800" }} > View Points</Button>
            </Link>
          </Row>
        </Col>
      </Row>
      <Form
        layout="vertical"
        labelCol={{ span: 5 }}
        className="posForm"
        initialValues={props.data}
        onFinish={onFinish}
      >
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              name="ContactLink"
              label="Contact Link "
              className="da-mb-16"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="AvailableforPuchchase"
              label="Purchase "
              className="da-mb-16"
            >
              <Select
                placeholder="Select a "
                options={[
                  { label: "Yes ", value: "yes" },
                  { label: " No", value: "No" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="ShowTree" label="Show Tree " className="da-mb-16">
              <Select
                placeholder="Select "
                options={[
                  { label: "Yes ", value: "yes" },
                  { label: " No", value: "No" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="Headlinde" label="Headlinde " className="da-mb-16">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="subline" label="subline " className="da-mb-16">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="Latitude" label="Lat " className="da-mb-16">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="Longitude" label="Long " className="da-mb-16">
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              name="Description"
              label="Description :"
              className="da-mb-16"
            >
              <TextArea
                className="posDiscription"
                rows={100}
                placeholder="Description"
                maxLength={100}
              />
            </Form.Item>
          </Col>


          <Col className="gutter-row" xs={24} md={12}      >
            <Form.Item
              name="ButtonText"
              label="  Button Text  "
              className="da-mb-16"
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="Model"
             
              label="Button  URL "
              className="da-mb-16"
            >
              <Input />
            </Form.Item>
          </Col> */}
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="pkg"
              label="Package"
              className="da-mb-16"
            >
              <Select
                showSearch
                onChange={handleChange}
                placeholder="Select a package"
                options={[
                  { label: "Package A", value: "Package A" },
                  { label: "Package B", value: "Package B" },
                  { label: "Package C", value: "Package C" },
                ]}
              />
            </Form.Item>
          </Col>
          {select === "Package C" ? (
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                name="upload"
                label="Upload file "
                className="da-mb-16"
              >
                <Upload
                  accept=".glb"
                  {...prop}
                  maxCount={1}
                  listType="picture"
                  defaultFileList={[...fileList]
                  }
                >
                  <Button className="uploadFile" icon={<UploadOutlined />}>
                    Chose File
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="Model"
              label="Select Model"
              className="da-mb-16"
            >
              <Select
                showSearch
                // onChange={handleChange}
                placeholder="Select a Model"
                options={[
                  { label: "Tree A", value: "Tree A" },
                  { label: "Tree B", value: "Tree B" },
                  { label: "Tree C", value: "Tree C" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="" label="  Colors  " className="da-mb-16">
              <div
                className="App"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div className="sketchpicker " >
                  <h6 className="heading">Selected Color</h6>
                  {sketchPickerColor === "" ? <p>No Color Selected yet</p> :
                    <div
                      style={{
                        backgroundColor: sketchPickerColor,
                        width: 100,
                        height: 50,
                        border: "2px solid white",
                      }}
                    ></div>
                  }
                  {/* Sketch Picker from react-color and handling color on onChange event */}
                  <SketchPicker
                    onChange={(color) => {
                      setSketchPickerColor(color.hex);
                    }}
                    color={sketchPickerColor}
                  />
                </div>
              </div>
            </Form.Item>
          </Col>

        </Row>
        <Form.Item style={{ textAlign: "end" }}>
          <ButtonComponent
            className="da-mr-10"
            type="primary"
            key="create_button"
            htmlType="submit"
            state={loader}
          >
            Save
          </ButtonComponent>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BasicInformation;

