import React from "react";
import { Route, Switch } from "react-router-dom";

import BasicInformation from "./users/components/edit/BasicInformation";
import EditUser from "./users/components/EditUser";
import IndexModel from "./users/components/IndexModel";
import IndexUser from "./users/IndexUser";

const UserModule = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/add-poi`} component={BasicInformation} />
    <Route exact path={`${match.url}/view-poi`} component={IndexUser} />
    <Route exact path={`${match.url}/edit/:id`} component={EditUser} />
    <Route exact path={`${match.url}/view/:id`} component={EditUser} />
    <Route exact path={`${match.url}/model`} component={IndexModel} />

    {/* ADMIN ROUTES */}
    {/* <Route exact path={`${match.url}/add-admin`} component={CreateAdmin} />
    <Route exact path={`${match.url}/view-admin`} component={IndexAdmin} />
    <Route exact path={`${match.url}/edit-admin/:id`} component={EditAdmin} /> */}
  </Switch>
);

export default UserModule;
