import {get, post, del, put, postFd } from "@utils/axios";

const api = "poi";
const events = "event/user";
const badges = "badges";
const poi = "poi/point";
export const getEvents = (payload) => {
    return post(events, payload);
}
export const getBadlges = () => {
    return get(badges);
}
export const getUsers = (payload) => {
    return get(api, payload);
}
export const deleteAll = (payload) => {
    return get(`${api}/delAll`, payload);
}

export const getFilters = () => {
    return get(`${api}/filters`);
}

export const getUserDependencies = () => {
    return get(`${api}/dependencies`);
}

export const getUser = (id) => {
    return get(`${api}/${id}`);
}

export const updateUser = (payload) => {
    return put(`${api}/${payload.id}`, payload);
}
export const createUser = (payload) => {
    return post(api, payload);
}
export const poiUpdate = (payload) => {
    return post(`${api}/update`, payload);
}
export const deleteUser = (payload) => {
    return post(`${api}/del`, payload);
}
export const addMulti = (payload) => {
    return post(`${api}/addMulti`, payload);
}
export const getPoi = (payload) => {
    return post(poi, payload);
}
export const delFile = (payload) => {
    return post("poi/delete-file", payload);
}