import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  ActionComponent,
  BodyComponent,
  HeaderComponent,
  TableComponent,
} from "@comps/components";
import {
  formatCompleteDataTime,
  makeRequest,
  notify,
  removeById,
  replaceById,
} from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// import CreateUser from "./components/CreateUser";
import { addMulti, deleteAll, deleteUser, getUsers } from "./requests";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Alert, Button, Col, Input, Modal, Row, Space, Table, Tag } from "antd";

const pageConfig = {
  headers: {
    title: "ADMIN",
    breadcrumb: [
      {
        name: "ADMIN",
        // path: "/user-management/users"
      },
    ],
  },
};

const IndexAdmin = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemData, setItemData] = useState({});
  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: "id",
    sortType: "desc",
  });
  const importRef = useRef();

  const importData = () => {
    document.getElementById("react-csv-reader-input").click()
  }



  const handleForce = (data, fileInfo) => {
    let payload = []
    data.forEach(element => {

      payload.push({
        ContactLink: element.contactlink,
        AvailableforPuchchase: element.availableforpuchchase,
        ShowTree: element.showtree,
        Headlinde: element.headlinde,
        subline: element.subline,
        Description: element.description,
        Latitude: element.latitude,
        Longitude: element.longitude,
        Colors: element.colors,
        Model: element.model,
        ButtonText: element.buttontext
      })
    });

    makeRequest(setLoader, addMulti, payload, onImportSuccess, onError);

    console.log(data, fileInfo)
  };
  const onImportSuccess = (error, msg) => {
    getAllUsers();
    notify("Data Import Successfuly");

  };
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [childComponent, setChildComponent] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      key: "firstName",
      title: "First Name",
      dataIndex: "firstName",
      // sorter: (a, b) => a.ContactLink.length - b.ContactLink.length,
      ...getColumnSearchProps("ContactLink"),
    },
    {
      key: "lastName",
      title: "Last Name",
      dataIndex: "lastName",
      // sorter: (a, b) => a.AvailableforPuchchase.length - b.AvailableforPuchchase.length,
      ...getColumnSearchProps("AvailableforPuchchase"),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      // sorter: (a, b) => a.ShowTree.length - b.ShowTree.length,
      ...getColumnSearchProps("ShowTree"),
    },

    {
      key: "country",
      title: "Country ",
      dataIndex: "country",
      // sorter: (a, b) => a.subline.length - b.subline.length,
      ...getColumnSearchProps("subline"),
    },
    {
      key: "city",
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.Latitude - b.Latitude,
      ...getColumnSearchProps("Latitude"),
    },



    {
      key: "actions",
      title: "Actions",
      render: (record) =>
        ActionComponent({
          each: record,
          onEdit: onEdit,
          onDelete: onDelete,
          // onView: onView,
        }),
    },
  ];
  const ActionComponentEx = (record) => {
    let icon = null;
    if (record) {
      if (record.is_active) {
        icon = <CloseOutlined className="icon-style da-text-color-danger-1" />;
      } else {
        icon = <CheckOutlined className="icon-style da-text-color-success-1" />;
      }
    }
    return <ActionComponent each={record} onView={onView}></ActionComponent>;
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [pagination]);

  const getAllUsers = () => {
    let payload = {
      start: pagination.current - 1,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
    };
    makeRequest(setLoader, getUsers, payload, onSuccess, null);
  };

  // const activateDeactiveUser = (user) => {
  //   makeRequest(setLoader, activateUserRequest, user, onActivateSuccess, onError);
  // }

  const onSuccess = (response) => {
    const res = response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setDataSource(res);
  };




  const onView = (record) => {
    history.push(`/poi/edit/${record._id}?view=false`);


  };

  const onEdit = (record) => {

    history.push(`/admin/edit-admin/${record._id}`);
  };

  const onDelete = (record) => {
    let payload = {
      id: [record._id.toString()],
    };
    makeRequest(setLoader, deleteUser, payload, onDeleteSuccess, onError);
  };

  const onDeleteSuccess = (response, msg) => {
    getAllUsers();

    notify("Admin delete");

  };

  const onError = (error, msg) => {
    notify("Server Error");
  };
  const clearData = (type) => {
    makeRequest(setLoader, deleteAll, null, onDeleteSuccess, onError);

  }

  return (
    <>
      {childComponent}
      {alert ? <Alert
        message="Are you sure you want Detele all points ! "
        type="error"
        action={
          <Space>
            <Button onClick={clearData} size="small" type="danger">
              Yes Clear All Data
            </Button>
          </Space>
        }
        closable onClick={() => setAlert(false)}
      /> : ""}

      <HeaderComponent headers={pageConfig.headers}></HeaderComponent>

      <Row justify="center" >

        <Button style={{ width: "30%", marginTop: "20px", marginBottom: "20px" }} type="primary" >
          <NavLink to="/admin/add-admin"  >
            ADD ADMIN
          </NavLink>

        </Button>
      </Row>
      <BodyComponent>
        {/* <FilterComponent filters={availableFilters} onFilter={setFilters} api={getFilters} /> */}

        <Table
          loader={loader}
          columns={columns}
          dataSource={dataSource}
          pagination={{ ...pagination, total: totalRecords }}
        // onChange={handleTableChange}
        />
      </BodyComponent>
    </>
  );
};

export default IndexAdmin;
