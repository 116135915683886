import { Route, Switch } from "react-router-dom";
import IndexUser from "../userManagement/users/IndexUser";
import IndexEvents from "./IndexSubmission";


const EventsModule = ({ match }) => (
  <Switch>
     <Route exact path={ `${match.url}/event` } component={ IndexEvents } />

  </Switch>
);

export default EventsModule;
