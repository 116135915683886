import React from "react";
import { Button, Col, Popconfirm, Row } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, WarningOutlined, PlusOutlined } from "@ant-design/icons";
import "./layouts-styles.css"

const classes = "da-px-10 da-my-0";

const BaseAction = (props) => {
  return (
    <>
      <Row justify="n" >

        {props.onView ?
          <Col sm={12}  md={8} >
            <Button className={classes} type="link" size="middle" onClick={() => view(props)}><EyeOutlined className="icon-style da-text-color-info-1" />
            </Button>
          </Col>
          : null}

        {props.onAdd ?
          <Col sm={12}  md={8} >

            <Button className={classes} type="link" size="middle" onClick={() => add(props)}><PlusOutlined className="icon-style" />
            </Button>
          </Col>
          : null}
        {props.onEdit ?
          <Col sm={12}  md={8} >


            <Button className={classes} type="link" size="middle" onClick={() => edit(props)}><EditOutlined className="icon-style da-text-color-warning-1" />
            </Button>
          </Col>
          : null}
        {props.onDelete ? (
          <Col sm={12}  md={8} >

            <Popconfirm title="Are you sure?" icon={<WarningOutlined />} onConfirm={() => del(props)}>
              <Button className={classes} type="link" size="middle"><DeleteOutlined className="icon-style  da-text-color-danger-1" /></Button>
            </Popconfirm>
          </Col>
        ) : null}
        {props.children}
      </Row>
    </>
  );
};

const view = (props) => {
  if (props.onView) {
    props.onView(props.each);
  }
}

const edit = (props) => {
  if (props.onEdit) {
    props.onEdit(props.each);
  }
}

const add = (props) => {
  if (props.onAdd) {
    props.onAdd(props.each);
  }
}

const del = (props) => {
  if (props.onDelete) {
    props.onDelete(props.each);
  }
}

export default BaseAction;
