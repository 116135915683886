import { ButtonComponent } from "@comps/components";
import { makeRequest, notify } from "@utils/helpers";
import { Col, Form, Input, message, Row, Skeleton } from "antd";
import React, { useState } from "react";


import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { adminUpdate, getAdmin } from "../requests";


const EditAdmin = (props) => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const view = parameters.get('view');
  const history = useHistory();
  const [loader, setLoader] = useState("");
  const [errors, setErrors] = useState([]);
  const [poiData, setPoiData] = useState({})


  

  const onFinish = (payload) => {
    let load = {
      id: id,
      ...payload,
    }

    makeRequest(Function, adminUpdate, load, success, onError);
  };

  
  const success = (data, res) => {
    history.push(`/admin/view-admin`);
    notify("Point Update  Successfully",);



  };
  const onSuccess = (data, res) => {
    setPoiData(res.response)
  };

  const onError = (err) => {

    let errorList = [];
    errorList["password"] = err;
    setErrors(errorList);
  };
  const { id } = useParams()
  const getOnePoi = () => {
    let payload = {
      id: id
    }
    makeRequest(setLoader, getAdmin, payload, onSuccess, null);

  }

  useEffect(() => {
    getOnePoi()
  }, [])

  if (loader == true) {
    return <Skeleton />
  }


  return (
    <div>
      <Row>
        <Col xs={24}>
          <h2 className="posHeadres">EDIT ADMIN</h2>
        </Col>
      </Row>
      <Form
        layout="vertical"
        labelCol={{ span: 5 }}
        className="posForm"
        initialValues={poiData}
        onFinish={onFinish}
      >
         <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="firstName"
              label="First Name "
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="email"
              label="Email "
              className="da-mb-16"
              rules={rules.email}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="country"
              label="Country"
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="city"
              label="City "
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
       
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="password"
              label="Password "
              className="da-mb-16"
              rules={rules.password}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "end" }}>
          {view == "false" ? (
            <div></div>
          ) :
            <ButtonComponent

              className="da-mr-10"
              type="primary"
              key="create_button"
              htmlType="submit"
              state={loader}
            >
              Save

            </ButtonComponent>
          }
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditAdmin;

const rules = {
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "This field is required" },
  ],
  password: [
    { required: true, message: 'This field is required', },
    { min: 6, message: 'Minimum password length is 6', },
    { max: 30, message: 'Maximum password length is 30', },
  ],
  name: [
    { required: true, message: 'This field is required', },
  ]
};