import { Col, Row } from "antd";
import React from "react"
import { useEffect,  useState } from "react";
import {

  makeRequest,
 
} from "@utils/helpers";
import {get } from "@utils/axios";

const Dashboard = () => {
  const api = "poi";
  const [loader, setLoader] = useState(false);
  const [totalnumber, setTotalNumber] = useState(0);
  useEffect(() => {
    getAllUsers();
    
  }, []);
  const getAllUsers = () => {
    let payload = {
    };
    makeRequest(setLoader, getUsers, payload, onSuccess, null);
  };
  const getUsers = (payload) => {
    return get(api, payload);
  }
  const onSuccess = (response) => {
    setTotalNumber(
      response.length
    )
  };
 
  return (
    <div>
      <Row className="dashboardLayout" gutter={[16, 24]}>
        <Col span={7}>
          <div className="dashboardBox">
            <h5 className="poiTotal">TOTAL POINT</h5>
            <p className="poiTotals">{totalnumber}</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard;
