import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateAdmin from "./components/CreateAdmin";
import EditAdmin from "./components/EditAdmin";
import IndexAdmin from "./IndexAdmin";


const AdminModule = ({ match }) => (
  <Switch>
  
    <Route exact path={`${match.url}/add-admin`} component={CreateAdmin} />
    <Route exact path={`${match.url}/view-admin`} component={IndexAdmin} />
    <Route exact path={`${match.url}/edit-admin/:id`} component={EditAdmin} />
  </Switch>
);

export default AdminModule;
