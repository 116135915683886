import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Form, Input, Row } from "antd";

import { ButtonComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";

import AuthCommon from "../components/AuthCommon";
import { forget } from "../requests";


const ForgetPassword = () => {

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const onFinish = (payload) => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('key');
    const load = {
      key: value,
      ...payload
    }
    makeRequest(setLoader, forget, load, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Password Reset", res.msg);
    setIsUpdate(true)
  }

  const onError = (err) => {
    
    let errorList = [];
    errorList['email'] = "Something went wrong";
    setErrors(errorList);
  }
  return (
    <Row gutter={[32, 0]} className="da-authentication-page">
      <AuthCommon />

      <Col md={12}>
        <Row className="da-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="da-px-sm-8 da-pt-24 da-pb-48"
          >
            <h1 className="da-mb-sm-0">Recover Password</h1>
            {isUpdate === false ?
              <Form
                layout="vertical"
                name="basic"
                className="da-mt-sm-16 da-mt-32"
                onFinish={onFinish}
              >
                <Form.Item name="password" label="New Password :"
                  {...getErrorProps(errors['email'])}
                >
                  <Input placeholder="**********" />
                </Form.Item>

                <Form.Item className="da-mt-16 da-mb-8">
                  <ButtonComponent block type="primary" htmlType="submit" state={loader}>
                    Reset Password
                  </ButtonComponent>
                </Form.Item>
              </Form>
              : ""}

          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ForgetPassword;

const rules = {
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "Please input your email!" },
  ]
};
