import { Route, Switch } from "react-router-dom";

import Dashboard from "./dashboard/components/Dashboard";
import EventsModule from "./events/routes";
import AdminModule from "./userManagement/admin/routes";
import UserModule from "./userManagement/routes";
import IndexUser from "./userManagement/users/IndexUser";

const ModuleRoutes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={IndexUser} />
    <Route path={`${match.url}poi`} component={UserModule} />
    <Route path={`${match.url}admin`} component={AdminModule} />
    <Route path={`${match.url}events`} component={EventsModule} />
    {/* <Route path={`${match.url}reset-password`} component={RecoverPassword} /> */}

  </Switch>
);

export default ModuleRoutes;
