import { UploadOutlined } from "@ant-design/icons";
import { ButtonComponent } from "@comps/components";
import { makeRequest, notify } from "@utils/helpers";
import { Button, Col, Form, Input, message, Row, Select, Skeleton, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";


import { SketchPicker, BlockPicker } from "react-color";
import { useEffect } from "react";
import { createUser, delFile, getOnePoint, getPoi, poiUpdate } from "../requests";
import { NavLink, useHistory, useParams } from "react-router-dom";
const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },

  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const EditUser = (props) => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const view = parameters.get('view');
  const history = useHistory();
  const [loader, setLoader] = useState("");
  const [errors, setErrors] = useState([]);
  const [poiData, setPoiData] = useState({})
  const [select, setSelect] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [sketchPickerColor, setSketchPickerColor] = useState("");


  const splitLocation = poiData.ModalLink?.split("/");
  const prop = {
    name: "modal",
    action: `${process.env.REACT_APP_API_BASE_URL}/poi/upload-modal`,
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setFileUrl(info.file?.response?.response?.url)
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove() {

      setPoiData((obj) => ({ ...obj, ModalLink: "" }))
      setFileUrl("")
      const splitLocation = fileUrl.split("/");
      let payload
      if (splitLocation[3]) {
        payload = {
          path: `${splitLocation[3]}/${splitLocation[4]}`
        }
      } else {
        payload = {
          path: `${splitLocation[0]}/${splitLocation[1]}`
        }
      }
      makeRequest(setLoader, delFile, payload, null, null);
    },

  };

  const onFinish = (payload) => {
    const splitModel = fileUrl.split("/");

    let modelLink = ""
    if (splitModel[0] == "http:" || splitModel[0] == "https:") {
      modelLink = fileUrl
    } else if (fileUrl != "" && select == "Package C") {
      modelLink = `https://app.badkissingenentdecken.de/${fileUrl}`
    }

    let load = {
      ModalLink: modelLink,
      id: id,
      Colors: sketchPickerColor,
      ...payload,
    }

    makeRequest(Function, poiUpdate, load, success, onError);
  };

  const handleChange = (value) => {
    setSelect(value);
  };
  const success = (data, res) => {
    history.push(`/poi/view-poi`);
    notify("Point Update  Successfully",);



  };
  const onSuccess = (data, res) => {
    setPoiData(res.response)
    setSelect(res.response.pkg)
    setSketchPickerColor(res.response.Colors)
    setFileUrl(res.response.ModalLink)
  };

  const onError = (err) => {

    let errorList = [];
    errorList["password"] = err;
    setErrors(errorList);
  };
  const { id } = useParams()
  const getOnePoi = () => {
    let payload = {
      id: id
    }
    makeRequest(setLoader, getPoi, payload, onSuccess, null);

  }

  useEffect(() => {
    getOnePoi()
  }, [])

  if (loader == true) {
    return <Skeleton />
  }


  return (
    <div>
      <Row>
        <Col xs={24}>
          <h2 className="posHeadres">{view == "false" ? "VIEW POINT" : "EDIT POINT"}</h2>
        </Col>
      </Row>
      <Form
        layout="vertical"
        labelCol={{ span: 5 }}
        className="posForm"
        initialValues={poiData}
        onFinish={onFinish}
      >
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              name="ContactLink"
              label="Contact Link "
              className="da-mb-16"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="AvailableforPuchchase"
              label="Purchase "
              className="da-mb-16"
            >
              <Select
                disabled={view == "false" ? true : false}
                placeholder="Select a "
                options={[
                  { label: "Yes ", value: "yes" },
                  { label: " No", value: "No" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="ShowTree" label="Show Tree " className="da-mb-16">
              <Select
                disabled={view == "false" ? true : false}
                placeholder="Select "
                options={[
                  { label: "Yes ", value: "yes" },
                  { label: " No", value: "No" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>

            <Form.Item name="Headlinde" label="Headlinde " className="da-mb-16">
              <Input disabled={view == "false" ? true : false} />
            </Form.Item>

          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="subline" label="subline " className="da-mb-16">
              <Input disabled={view == "false" ? true : false} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="Latitude" label="Lat " className="da-mb-16">
              <Input disabled={view == "false" ? true : false} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="Longitude" label="Long " className="da-mb-16">
              <Input disabled={view == "false" ? true : false} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Form.Item
              name="Description"
              label="Description :"
              className="da-mb-16"
            >
              <TextArea
                disabled={view == "false" ? true : false}
                className="posDiscription"
                rows={100}
                placeholder="Description"
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}      >
            <Form.Item

              name="ButtonText"
              label="  Button Text  "
              className="da-mb-16"
            >
              <Input disabled={view == "false" ? true : false} />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="Model"
             
              label="Button  URL "
              className="da-mb-16"
            >
              <Input />
            </Form.Item>
          </Col> */}
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="pkg"
              label="Package"
              className="da-mb-16"
            >
              <Select
                disabled={view == "false" ? true : false}
                showSearch
                onChange={handleChange}
                placeholder="Select a package"
                options={[
                  { label: "Package A", value: "Package A" },
                  { label: "Package B", value: "Package B" },
                  { label: "Package C", value: "Package C" },
                ]}
              />
            </Form.Item>
          </Col>
          {select === "Package C" ? (
            <Row justify="space-between" gutter={[16, 24]} style={{ width: "100%" }}  >

              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  name="upload"
                  label="Upload file "
                  className="da-mb-16"
                >
                  <Upload

                    accept=".glb"
                    disabled={view == "false" ? true : false}
                    {...prop}
                    maxCount={1}
                    listType="picture"
                    defaultFileList={poiData.ModalLink != "" ? [{
                      name: poiData.ModalLink,
                      status: 'done'
                    }] : []
                    }
                  >
                    <Button className="uploadFile" icon={<UploadOutlined className="da-pr-4" />}
                      disabled={view == "false" ? true : false}>
                      {poiData.ModalLink == "" ?
                        "Chose File" : " please delete model first before uploading new"
                      }
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12}>
                <Form.Item
                  // name="upload"
                  label="Model"
                  className="da-mb-16"
                >
                  {splitLocation[4] &&
                    <NavLink to={`/poi/model?link=${poiData.ModalLink}&id=${id}&x=${poiData?.x}&y=${poiData?.y}&z=${poiData?.z}`} >
                      <Button  >Adjust Model</Button>

                    </NavLink>
                  }
                </Form.Item>
              </Col>
            </Row>

          ) : (
            ""
          )}
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              name="Model"
              label="Select Model"
              className="da-mb-16"
            >
              <Select
                showSearch
                // onChange={handleChange}
                placeholder="Select a Model"
                options={[
                  { label: "Tree A", value: "Tree A" },
                  { label: "Tree B", value: "Tree B" },
                  { label: "Tree C", value: "Tree C" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item name="" label="  Colors  " className="da-mb-16">

              <div
                className="App"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div className="sketchpicker " >
                  <h6 className="heading">Selected Color</h6>
                  {sketchPickerColor === "" ? <p>No Color Selected yet</p> :
                    <div
                      style={{
                        backgroundColor: sketchPickerColor,
                        width: 100,
                        height: 50,
                        border: "2px solid white",
                      }}
                    ></div>
                  }
                  {/* Sketch Picker from react-color and handling color on onChange event */}
                  <SketchPicker
                    onChange={(color) => {
                      setSketchPickerColor(color.hex);
                    }}
                    color={sketchPickerColor}
                  />
                </div>
              </div>
            </Form.Item>
          </Col>

        </Row>
        <Form.Item style={{ textAlign: "end" }}>
          {view == "false" ? (
            <div></div>
          ) :
            <ButtonComponent

              className="da-mr-10"
              type="primary"
              key="create_button"
              htmlType="submit"
              state={loader}
            >
              Save

            </ButtonComponent>
          }
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUser;

