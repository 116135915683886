import { Col, Modal, Row } from 'antd';
import React from 'react';

const BasicInformation = (props) => {

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal
        className='basicModal'
        title="Basic Modal"
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
      >
        {/* Row NO 1 */}
        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Contact Links</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.ContactLink}</h5>
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Available For Purchase</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.AvailableforPuchchase}</h5>
              </Col>
            </Row>
          </Col>
          {/* <Col sm={7} >
            <h5>Available For Purchase</h5>
          </Col>
          <Col sm={5}>
            <h5>{props.itemData.AvailableforPuchchase}</h5>
          </Col> */}
        </Row>
        {/* <Row>
          <Col xs={} >
            <hr />
          </Col>
        </Row> */}

        {/* Row No 2 */}
        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Show Tree</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.ShowTree}</h5>
              </Col>
            </Row>

          </Col>
          <Col sm={7}>
            <h5>Headline</h5>
          </Col>
          <Col sm={5}>
            <h5>{props.itemData.Headlinde}</h5>
          </Col>
        </Row>
        <hr />


        {/* Row No 3 */}

        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Subline</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.subline}</h5>
              </Col>
            </Row>

          </Col>
          <Col sm={7}>
            <h5>Lat</h5>
          </Col>
          <Col sm={5}>
            <h5>{props.itemData.Latitude}</h5>
          </Col>
        </Row>
        <hr />



        {/* Row No 4 */}
        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Long</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.Longitude}</h5>
              </Col>
            </Row>

          </Col>
          <Col sm={7}>
            <h5>Colors</h5>
          </Col>
          <Col sm={5}>
            <h5>{props.itemData.Colors}</h5>
          </Col>

        </Row>
        <hr />

        {/* Row No 5 */}
        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Modal</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.Model}</h5>
              </Col>
            </Row>

          </Col>
          <Col sm={7}>
            <h5>Text For Button</h5>
          </Col>
          <Col sm={5}>
            <h5>{props.itemData.ButtonText}</h5>
          </Col>
        </Row>
        <hr />

        {/* Row No 6 */}
        <Row className="heading-styling">
          <Col sm={12}>
            <Row align='space-between' >
              <Col sm={11}>
                <h5>Text For URl</h5>
              </Col>
              <Col sm={11}>
                <h5>{props.itemData.TextForURL}</h5>
              </Col>
            </Row>

          </Col>

        </Row>
        <hr />

      </Modal>


      {/* <ModalComponent className="modalWidth"  mainTitle="POI"  visible={true} footer={footer} onCancel={() => props.onCreated(false)} onOk={handleOk} >
       
    </ModalComponent> */}
    </>
  );
};

export default BasicInformation;