import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  ActionComponent,
  BodyComponent,
  HeaderComponent,
  TableComponent,
} from "@comps/components";
import {
  formatCompleteDataTime,
  makeRequest,
  notify,
  removeById,
  replaceById,
} from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// import CreateUser from "./components/CreateUser";
import { addMulti, deleteAll, deleteUser, getUsers } from "./requests";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Alert, Button, Col, Input, Modal, Row, Space, Tag } from "antd";
import BasicInformation from "./components/view/BasicInformation";
import CSVReader from "react-csv-reader";

const pageConfig = {
  headers: {
    title: "POINT",
    breadcrumb: [
      {
        name: "POINT",
        // path: "/user-management/users"
      },
    ],
  },
};

const IndexUser = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemData, setItemData] = useState({});
  const [alert, setAlert] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: "id",
    sortType: "desc",
  });
  const importRef = useRef();

  const importData = () => {
    document.getElementById("react-csv-reader-input").click()
  }



  const handleForce = (data, fileInfo) => {
    let payload = []
    data.forEach(element => {

      payload.push({
        ContactLink: element.contactlink,
        AvailableforPuchchase: element.availableforpuchchase,
        ShowTree: element.showtree,
        Headlinde: element.headlinde,
        subline: element.subline,
        Description: element.description,
        Latitude: element.latitude,
        Longitude: element.longitude,
        Colors: element.colors,
        Model: element.model,
        ButtonText: element.buttontext
      })
    });

    makeRequest(setLoader, addMulti, payload, onImportSuccess, onError);

    console.log(data, fileInfo)
  };
  const onImportSuccess = (error, msg) => {
    getAllUsers();
    notify("Data Import Successfuly");

  };
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [childComponent, setChildComponent] = useState(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      key: "ContactLink",
      title: "Contacts Links",
      dataIndex: "ContactLink",
      // sorter: (a, b) => a.ContactLink.length - b.ContactLink.length,
      ...getColumnSearchProps("ContactLink"),
    },
    {
      key: "AvailableforPuchchase",
      title: "Available",
      dataIndex: "AvailableforPuchchase",
      // sorter: (a, b) => a.AvailableforPuchchase.length - b.AvailableforPuchchase.length,
      ...getColumnSearchProps("AvailableforPuchchase"),
    },
    {
      key: "ShowTree",
      title: "Show",
      dataIndex: "ShowTree",
      // sorter: (a, b) => a.ShowTree.length - b.ShowTree.length,
      ...getColumnSearchProps("ShowTree"),
    },

    {
      key: "subline",
      title: "subline ",
      dataIndex: "subline",
      // sorter: (a, b) => a.subline.length - b.subline.length,
      ...getColumnSearchProps("subline"),
    },
    {
      key: "Latitude",
      title: "Lat",
      dataIndex: "Latitude",
      sorter: (a, b) => a.Latitude - b.Latitude,
      ...getColumnSearchProps("Latitude"),
    },
    {
      key: "Longitude",
      title: "Long",
      dataIndex: "Longitude",
      sorter: (a, b) => a.Longitude - b.Longitude,
      ...getColumnSearchProps("Longitude"),
    },
    {
      key: "Colors",
      title: "Colors",
      dataIndex: "Colors",
      sorter: false,
      ...getColumnSearchProps("Colors"),
      render: (color) => <Tag color={color} >
        {color}
      </Tag>

    },
    {
      key: "Model",
      title: "Model",
      dataIndex: "Model",
      sorter: false,
      // ...getColumnSearchProps("Model"),
    },

    {
      key: "ButtonText",
      title: "Button Text",
      dataIndex: "ButtonText",
      sorter: false,
      ...getColumnSearchProps("ButtonText"),
    },

    // {
    //   key: 'Lat',
    //   title: 'Available Package ',
    //   dataIndex: 'Lat',
    //   sorter: false,
    //   ...getColumnSearchProps('email'),

    // },
    // {
    //   key: 'Lat',
    //   title: '3D images ',
    //   dataIndex: 'Lat',
    //   sorter: false,
    //   ...getColumnSearchProps('email'),

    // },
    {
      key: "actions",
      title: "Actions",
      render: (record) =>
        ActionComponent({
          each: record,
          onEdit: onEdit,
          onDelete: onDelete,
          onView: onView,
        }),
    },
  ];
  const ActionComponentEx = (record) => {
    let icon = null;
    if (record) {
      if (record.is_active) {
        icon = <CloseOutlined className="icon-style da-text-color-danger-1" />;
      } else {
        icon = <CheckOutlined className="icon-style da-text-color-success-1" />;
      }
    }
    return <ActionComponent each={record} onView={onView}></ActionComponent>;
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, [pagination]);

  const getAllUsers = () => {
    let payload = {
      start: pagination.current - 1,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
    };
    makeRequest(setLoader, getUsers, payload, onSuccess, null);
  };

  // const activateDeactiveUser = (user) => {
  //   makeRequest(setLoader, activateUserRequest, user, onActivateSuccess, onError);
  // }
  const onActivateSuccess = (res, msg) => {
    setDataSource(replaceById(dataSource, res));
    notify(msg.msg);
  };
  const onSuccess = (response) => {
    const res = response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setDataSource(res);
  };

  const onImported = (res) => {
    getAllUsers();
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  // Create component modal
  // const onCreate = () => {
  //   setChildComponent(<CreateUser onCreated={onCreated} />);
  // }

  const onCreated = (each) => {
    if (!each) {
      setChildComponent(null);
    }
    setDataSource([...dataSource, each.object]);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onView = (record) => {
    history.push(`/poi/edit/${record._id}?view=false`);


  };

  const onEdit = (record) => {

    history.push(`/poi/edit/${record._id}`);
  };

  const onDelete = (record) => {
    let payload = {
      id: [record._id.toString()],
    };
    makeRequest(setLoader, deleteUser, payload, onDeleteSuccess, onError);
  };

  const onDeleteSuccess = (response, msg) => {
    getAllUsers();
    if (response.deletedCount == 1) {

      notify("Point delete");
    } else if (response.deletedCount > 1) {
      notify("ALl points Delete");
    }
  };

  const onError = (error, msg) => {
    notify("Server Error");
  };
  const clearData = (type) => {
    makeRequest(setLoader, deleteAll, null, onDeleteSuccess, onError);

  }

  return (
    <>
      {childComponent}
      {alert ? <Alert
        message="Are you sure you want Detele all points ! "
        type="error"
        action={
          <Space>
            <Button onClick={clearData} size="small" type="danger">
              Yes Clear All Data
            </Button>
          </Space>
        }
        closable onClick={() => setAlert(false)}
      /> : ""}

      <HeaderComponent headers={pageConfig.headers}></HeaderComponent>

      <Row justify="center" >

        <Button style={{ width: "30%", marginTop: "20px", marginBottom: "20px" }} type="primary" >
          <NavLink to="/poi/add-poi"  >
            ADD POINT
          </NavLink>

        </Button>
      </Row>
      <BodyComponent>
        {/* <FilterComponent filters={availableFilters} onFilter={setFilters} api={getFilters} /> */}
        <Row className="da-mt-12 da-mb-12" align="space-between"  >
          <Col md={0} lg={0} xl={0} sm={24} xs={24} >
            <h1 style={{ color: "#0093cd" }} >Total Points {dataSource.length}
            </h1>
          </Col>
          <Col md={3} sm={6} >
            <Button onClick={() => setAlert(true)} danger >Clear All Data</Button>
          </Col>
          <Col md={12} lg={6} sm={0} xs={0} >
            <h1 style={{ color: "#0093cd" }} >Total Points {dataSource.length}
            </h1>
          </Col>
          <Col md={4} lg={3} sm={6} >
            <Row align="end" >

              <Button style={{background:"#0093cd"  , color : "white" , fontWieght :"800"}} onClick={importData} >
                Import  CSV
              </Button>
              <CSVReader
                ref={importRef}
                cssClass="react-csv-input"
                label="Import CSV file"
                onFileLoaded={handleForce}
                parserOptions={papaparseOptions}
                accept=".csv"

              />
            </Row>
          </Col>

        </Row>
        <TableComponent
          loader={loader}
          columns={columns}
          dataSource={dataSource}
          pagination={{ ...pagination, total: totalRecords }}
          onChange={handleTableChange}
        />
      </BodyComponent>
      <BasicInformation totalRecords={totalRecords} isModalVisible={isModalVisible} handleCancel={handleCancel} handleOk={handleOk} itemData={itemData} />
    </>
  );
};

export default IndexUser;
