import { hasPermission } from "@utils/helpers";
import { Drawer, Layout, Menu } from "antd";
import React, { useContext, useMemo } from "react";
import { RiCloseFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../utils/context";
import MenuLogo from "./logo";
// import navigation from "./navigation";









const { Sider } = Layout;

export default function Sidebar(props) {
  const { visible, setVisible } = props;
  const { collapsed } = useContext(AppContext);
  // Location
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(pathname.split("/"));
  console.log('log 2 ', splitLocation);

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };
  const openTabs = () => {
    console.log(splitLocation[splitLocation.length - 2])
    //  
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const navigation = [{
    id: "userManagement",
    title: "POINT",
    icon: <svg fill="green" xmlns="http://www.w3.org/2000/svg" width="26" height="26" class="bi bi-tree-fill" viewBox="0 0 16 16">
      <path d="M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777l-3-4.5z" />
    </svg >,
    uri: "/poi",
    permissionKey: true,

    children: [{
      id: "users",
      title: "Add Point",
      uri: "/add-poi",
      permissionKey: true,

    },
    {
      id: "users",
      title: "View Point",
      uri: "/view-poi",
      permissionKey: true,
    },

    ],
  },
  {
    id: "adminManagement",
    title: "ADMIN",
    icon: <svg fill="green" xmlns="http://www.w3.org/2000/svg" width="26" height="26" class="bi bi-tree-fill" viewBox="0 0 16 16">
      <path d="M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777l-3-4.5z" />
    </svg >,
    uri: "/admin",
    permissionKey: user?.type == "super admin" ? true : false ,

    children: [{
      id: "users",
      title: "Add Admin",
      uri: "/add-admin",
      permissionKey: true,

    },
    {
      id: "users",
      title: "View Admin",
      uri: "/view-admin",
      permissionKey: true,
    },

    ],
  },

  ];
  const MemoizedMenuItems = useMemo(
    () => <>{navigation.map((item, index) =>  MenuItem(item, index, "0", item.uri, "/", openTabs))}</>,
    []
  );

  const MainMenu = () => {
    const menu = (
      <Menu
        mode="inline"
        defaultSelectedKeys={[
          splitLocation.length === 5
            ? splitLocation[splitLocation.length - 2]
            : null,
          "/" + splitLocation[splitLocation.length - 1],
        ]}
        defaultOpenKeys={[
          splitLocation.length === 5
            ? splitLocation[splitLocation.length - 3]
            : null,
          "/" + splitLocation[splitLocation.length - 2],
        ]}
      >
        {MemoizedMenuItems}
      </Menu>
    );

    if (!visible) {
      return menu;
    }

    return (
      <Drawer
        title={<MenuLogo onClose={onClose} />}
        className="da-mobile-sidebar"
        placement="left"
        closable={true}
        onClose={onClose}
        visible={visible}
        closeIcon={
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        }
      >
        {menu}
      </Drawer>
    );
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme="light"
      width={256}
      className="da-sidebar"
    >
      <MainMenu />
    </Sider>
  );
}

const MenuItem = (each, i, k, uri = "/", parentUri = "/", openTabs) => {
  let concat = `${k}-${i}`;
  if (!each.children || each.children === null) {
    each.children = [];
  }
  // console.log(process.env.REACT_APP_ENV);
  if (!each.permissionKey) {
    return <></>;
  }

  // implement has children

  if (each.children.length) {


    return (
      <Menu.SubMenu key={uri} title={each.title} icon={each.icon}>
        {each.children.map((child, j) => child.permissionKey && MenuItem(child, j, concat, child.uri, uri, openTabs))}
      </Menu.SubMenu>
    );
  }
  return (
    <Menu.Item key={uri} icon={each.icon}>
      <span onClick={openTabs} >
        <Link to={parentUri + uri}>{each.title}</Link>
      </span>
    </Menu.Item>
  );
};