import { ButtonComponent } from "@comps/components";
import { makeRequest, notify } from "@utils/helpers";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { createUser } from "../requests";

import { Link, useHistory } from "react-router-dom";


const CreateAdmin = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState("");


  const onFinish = (payload) => {

    let load = {

      ...payload,
    }

    makeRequest(setLoader, createUser, load, onSuccess, onError);
  };
  const onSuccess = (data, res) => {
    notify("Admin Created Successfully");
    history.push(`/admin/view-admin`);
  };

  const onError = (err, res) => {
    notify("Error" + err.data.errors.messageDetail);
  };

  const fileList = [

  ];

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Row align="space-between" >
            <Col span={12} >

              <h2 className="posHeadres">Add ADMIN</h2>
            </Col>
            <Link to="/admin/view-admin" >
              <Button style={{ height: "50px", marginTop: "35px", marginRight: "35px", backgroundColor: "#0093CD", fontWeight: "800" }} > View Admin</Button>
            </Link>
          </Row>
        </Col>
      </Row>
      <Form
        layout="vertical"
        labelCol={{ span: 5 }}
        className="posForm"
        initialValues={props.data}
        onFinish={onFinish}
      >
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="firstName"
              label="First Name "
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="email"
              label="Email "
              className="da-mb-16"
              rules={rules.email}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="country"
              label="Country"
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="city"
              label="City "
              className="da-mb-16"
              rules={rules.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={12}>
            <Form.Item
              name="password"
              label="Password "
              className="da-mb-16"
              rules={rules.password}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>
        <Form.Item style={{ textAlign: "end" }}>
          <ButtonComponent
            className="da-mr-10"
            type="primary"
            key="create_button"
            htmlType="submit"
            state={loader}
          >
            Save
          </ButtonComponent>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateAdmin;


const rules = {
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "This field is required" },
  ],
  password: [
    { required: true, message: 'This field is required', },
    { min: 6, message: 'Minimum password length is 6', },
    { max: 30, message: 'Maximum password length is 30', },
  ],
  name: [
    { required: true, message: 'This field is required', },
  ]
};
