import { Button, Col, Form, Input, Row, Skeleton } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { makeRequest, notify } from "@utils/helpers";
import { getPoi, getUser, poiUpdate } from "../requests";

// https://github.com/chandjr0/poiAdmin/blob/master/src/assets/Heidi_1.glb
class IndexAdmin extends Component {
  state = {
    x: 0,
    y: 0,
    z: 0,
    isLoading: true
  };

  modelObject = null;
  queryString = window.location.search;
  parameters = new URLSearchParams(this.queryString);
  id = this.parameters.get('id');

  componentDidMount() {
    const loader = new GLTFLoader();
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("azure"); // Set background color to white
    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 1, 5);
    scene.add(camera);

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    this.mount.appendChild(renderer.domElement);

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const link = parameters.get('link');

    // Load model
    loader.load(
      link,
      gltf => {
        this.modelObject = gltf.scene;
        this.modelObject.position.set(0, 0, 0); // Center the model
        this.modelObject.scale.set(1, 1, 1); // Scale the model
        scene.add(this.modelObject);
        this.setState({
          isLoading: false
        })
        makeRequest(Function, getPoi, { id: this.id }, success, Function);

      },
      undefined,
      error => {
        console.error(error);
      }
    );

    // Set up lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.8);
    const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight1.position.set(-1, 2, 4);
    directionalLight2.position.set(1, -2, -4);
    scene.add(ambientLight, directionalLight1, directionalLight2);

    // Animate scene
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.clear(); // Clear previous frame
      renderer.render(scene, camera);
    };
    animate();
    console.log("animate();", animate());
    const success = (res) => {
      this.setState({
        x: res.x,
        y: res.y,
        z: res.z,
        // isLoading: false
      })

      const degToRad = Math.PI / 180;
      // Create a new quaternion for the rotation in degrees
      const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
        new THREE.Euler(
          res.y * degToRad,
          res.x * degToRad,
          res.z * degToRad,
          'XYZ'
        )
      );
      // Apply the new quaternion to the model
      this.modelObject.quaternion.copy(deltaRotationQuaternion);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { x, y, z } = this.state;

    const degToRad = Math.PI / 180;



  }

  handleInputChange = (event, param) => {
    const value = event.target.value;
    this.setState({ [param]: value });
    // Convert degrees to radians
    const degToRad = Math.PI / 180;
    // Create a new quaternion for the rotation in degrees
    const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
      new THREE.Euler(
        this.state.y * degToRad,
        this.state.x * degToRad,
        this.state.z * degToRad,
        'XYZ'
      )
    );
    // Apply the new quaternion to the model
    this.modelObject.quaternion.copy(deltaRotationQuaternion);
  };
  onFinish = (data) => {
    this.setState({
      x: data.x ? data.x : "0",
      y: data.y ? data.y : "0",
      z: data.z ? data.z : "0"
    });
    console.log(this.state);
    // Convert degrees to radians
    const degToRad = Math.PI / 180;
    // Create a new quaternion for the rotation in degrees
    const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
      new THREE.Euler(
        this.state.y * degToRad,
        this.state.x * degToRad,
        this.state.z * degToRad,
        'XYZ'
      )
    );
    // Apply the new quaternion to the model
    this.modelObject.quaternion.copy(deltaRotationQuaternion);
  };

  success = (res) => {
    notify("Model poisition set")
  }
  setValues = () => {
    let payload = {
      id: this.id,
      x: this.state.x,
      y: this.state.y,
      z: this.state.z,
    }
    makeRequest(Function, poiUpdate, payload, this.success, Function);

  }

  render() {

    const { x, y, z, isLoading } = this.state;
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const xValue = parameters.get('x');
    const yValue = parameters.get('y');
    const zValue = parameters.get('z');
    return (
      <Row style={{ width: "100%" }} >
        {isLoading && <Skeleton active />

        }


        <Row style={{ display: isLoading && "none", width: "100%" }} >
          <Row justify='space-around' style={{ width: "100%" }} >
            <Button style={{ width: "100px", margin: "20px", backgroundColor: "#addbd0" }} >
              <Link to={`edit/${this.id}`} >

                Back
              </Link>
            </Button>
            <Button style={{ width: "100px", margin: "20px", backgroundColor: "#addbd0" }} onClick={this.setValues} >Set</Button>
          </Row>
          <Form
            layout="vertical"
            labelCol={{ span: 5 }}
            className="posForm"
            style={{ width: "100%" }}
            onFinish={this.onFinish}
            initialValues={{ x: xValue, y: yValue, z: zValue }}
          >
            <Row justify="space-between" style={{ width: "100%", marginBottom: "20px" }}>

              <Col xs={6}>
                <Form.Item
                  label="Value X"
                  className="da-mb-16"
                  name="x"
                >

                  <Input min={-360} max={360} type='number' placeholder="X value" value={x} onChange={(e) => this.handleInputChange(e, 'x')} />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  label="Value Y"
                  className="da-mb-16"
                  name="y"
                >

                  <Input type='number' placeholder="Y value" value={y} onChange={(e) => this.handleInputChange(e, 'y')} />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  label="Value Z"
                  className="da-mb-16"
                  name="z"
                >

                  <Input type='number' placeholder="Z value" value={z} onChange={(e) => this.handleInputChange(e, 'z')} />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item style={{ textAlign: "end" }}>

                  <Button
                    style={{ marginTop: "20px" }}
                    className="da-mr-10"
                    type="primary"
                    key="create_button"
                    htmlType="submit"
                  // state={loader}
                  >
                    Apply

                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div ref={ref => (this.mount = ref)} />;

        </Row>
      </Row>

    )
  }
}

export default IndexAdmin;
